import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import Header from "components/Headers/Header.js";
import moment from "moment";
import "moment-timezone";
import { collection, getDocs } from "firebase/firestore";
import { CSVLink } from "react-csv";
import { db } from "../firebase";
import axios from "axios";
import { toast } from "react-toastify";
import { chartOptions, parseOptions } from "variables/charts.js";
import { Line } from "react-chartjs-2";
import Chart from "chart.js";
import { error } from "jquery";
import { createGlobalStyle } from "styled-components";

let flag = false;
const options = {
  scales: {
    xAxes: [
      {
        // gridLines: {
        // display: false
        // }
      },
    ],
    yAxes: [
      {
        // gridLines: {
        //     display: false
        //     // color: colors.gray[900],
        //     // zeroLineColor: colors.gray[900],
        // },
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const Index = (props) => {
  const [startDate, setStartDate] = useState(
    moment().tz("America/Los_Angeles").subtract(7, "days")
  );
  const [endDate, setEndDate] = useState(
    moment().tz("America/Los_Angeles").endOf("day")
  );

  const [games, setGames] = useState([]);
  const [versions, setVersions] = useState([]);
  const [gamesNames, setGamesNames] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [mainTableData, setMainTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loading1, setLoading1] = useState(null);
  const [tableLoading, setTableLoading] = useState(null);
  const [gamesList, setGamesList] = useState([]);
  const [gameName, setGameName] = useState("");
  const [graphLabels, setGraphLabels] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [download, setDownload] = useState(false);
  const [csv, setCsv] = useState("");

  const [summaryAvg, setSummaryAvg] = useState({
    avgAnrRate: 0,
    avgCrashRate: 0,
    avgUserPerceivedCrashRate: 0,
    avgUserPerceivedAnrRate: 0,
  });
  useEffect(() => {
    console.log("Start date:", startDate.format());
    console.log("End date:", endDate.format());
  }, [startDate, endDate]);
  const [preDefinedDates, setPreDefinedDates] = useState([
    {
      label: "Last 7 Days",
      value: "7",
    },
    {
      label: "Last 15 Days",
      value: "15",
    },
    {
      label: "Last 30 Days",
      value: "30",
    },
    {
      label: "custom",
      value: "custom",
    },
  ]);
  const [selectedPreDefinedDate, setSelectedPreDefinedDate] = useState(
    preDefinedDates[0]
  );

  useEffect(() => {
    let avgANR = 0;
    let avgCrash = 0;
    let avgUPANRRate = 0;
    let avgUPCrashRate = 0;
    let sumANR = 0;
    let sumCrash = 0;
    let sumUPANRRate = 0;
    let sumUPCrashRate = 0;
    tableData.forEach((row) => {
      sumANR += row.anrRate * 100;
      sumCrash += row.crashRate * 100;
      sumUPCrashRate += row.userPerceivedCrashRate * 100;
      sumUPANRRate += row.userPerceivedAnrRate * 100;
    });
    avgANR = sumANR / tableData.length;
    avgCrash = sumCrash / tableData.length;
    avgUPCrashRate = sumUPCrashRate / tableData.length;
    avgUPANRRate = sumUPANRRate / tableData.length;
    setSummaryAvg({
      avgAnrRate: avgANR.toFixed(2),
      avgCrashRate: avgCrash.toFixed(2),
      avgUserPerceivedCrashRate: avgUPCrashRate.toFixed(2),
      avgUserPerceivedAnrRate: avgUPANRRate.toFixed(2),
    });
  }, [selectedVersion]);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  const data = {
    labels: graphLabels,
    datasets: [
      {
        label: "ANR Rate",
        data: tableData.map((_row) => (_row.anrRate * 100).toFixed(2)),
        // borderColor: 'rgb(255, 99, 132)',
        // backgroundColor: 'rgba(255, 99, 132, 0.5)',
        borderColor: "#8b10e8",
        fill: false,
        pointBackgroundColor: "#8b10e8",
        pointBorderColor: "#8b10e8",
        pointRadius: 4,
      },
      {
        label: "Crash Rate",
        data: tableData.map((_row) => (_row.crashRate * 100).toFixed(2)),
        borderColor: "#ff4704",
        fill: false,
        pointBackgroundColor: "#ff4704",
        pointBorderColor: "#ff4704",
        pointRadius: 4,
        // borderColor: 'rgb(53, 162, 235)',
        // backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  useEffect(() => {
    setDownload(false);
    setRefresh(false);
  }, [selectedGame, startDate, endDate]);

  useEffect(() => {
    console.log(props);
    loadGames().then((result) => {
      console.log(result);
      let gameNames = [];
      let gamesBO = [];
      result.forEach((_item) => {
        gameNames.push(_item.name);
        gamesBO[_item.name] = _item;
        // gamesBO.push({
        //  [_item.name] : _item
        // })
      });
      console.log(gameNames);
      // const gameNames = Object.values(result.reduce((acc, {name}) => {
      //     acc[name] = name;
      //     // acc[key] = key;
      //     return acc;
      // }, {}));
      console.log(gamesBO);
      const options = gameNames.map((name) => ({ value: name, label: name }));
      console.log(options);
      setGamesList(gamesBO);
      setGamesNames(options);
      setGames(result);
    });
  }, []);

  useEffect(() => {
    if (selectedPreDefinedDate !== "custom") {
      console.log("predefined date changed");
      const laTimezone = "America/Los_Angeles";
      if (selectedPreDefinedDate.value === "7") {
        setStartDate(moment().tz(laTimezone).subtract(7, "days"));
      } else if (selectedPreDefinedDate.value === "15") {
        setStartDate(moment().tz(laTimezone).subtract(15, "days"));
      } else if (selectedPreDefinedDate.value === "30") {
        setStartDate(moment().tz(laTimezone).subtract(30, "days"));
      }
      if (flag) {
        setEndDate(endDate.subtract(1, "days"));
        console.log("end official date is ", endDate);
        applyFilters();
      }
    }
  }, [selectedPreDefinedDate, flag]);

  useEffect(() => {
    console.log(summaryAvg);
    console.log(summaryAvg.avgUserPerceivedAnrRate > 0.47);
  }, [summaryAvg]);

  const clearFilters = () => {
    setSelectedGame(null);
    setSelectedVersion(null);
    setTableData(mainTableData);
    setSelectedPreDefinedDate(preDefinedDates[0]);
    // setStartDate(null);
    // setEndDate(null);
  };

  // zubair logic
  const csvFunction = () => {
    // console.log(selectedVersion, "selected version code");
    const stringVersionCode = selectedVersion?.label.toString();
    const stringStartDate = startDate.toString();
    const stringEndDate = endDate.toString();
    const convertedData = [];
    convertedData.push(["Filters"]);
    convertedData.push(["GAME NAME", "Start Date", "End Date", "Version Code"]);
    convertedData.push([
      gameName,
      stringStartDate,
      stringEndDate,
      stringVersionCode,
    ]);
    convertedData.push([
      "Name",
      "anrRate",
      "anrRate7dayUserWeighted",
      "crashRate",
      "crashRate7DayUserweighted",
      "startDate",
      "version",
    ]);

    tableData.forEach((item) => {
      const startDateString = `${item.startTime.day}-${item.startTime.month}-${item.startTime.year}`;
      // console.log(item, "ITEMS");
      convertedData.push([
        gameName,
        item.anrRate * 100,
        item.anrRate7dUserWeighted * 100,
        item.crashRate * 100,
        item.crashRate7dUserWeighted * 100,
        startDateString,
        item.versionCode,
      ]);
    });

    setCsv(convertedData);
  };

  // zubair logic end

  const refreshContent = async (e) => {
    if (e) e.preventDefault();

    try {
      setLoading("pending");
      setTableData([]); // Clear table data while loading
      setTableLoading("pending");
      if (!startDate || !endDate) {
        console.log("Invalid Date");
        toast.error("Invalid Date", {
          position: "top-right",
          autoClose: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
        setLoading(null);
        setLoading1(null);
        setTableLoading(null);
        return;
      }

      const reqBody = {
        startDate: startDate.format("DD-MM-YYYY"),
        endDate: endDate.format("DD-MM-YYYY"),
        name: gamesList[selectedGame.value].key,
        studioId: gamesList[selectedGame.value].studioName,
        version: "1.0",
      };

      const response = await axios.post(
        "https://us-central1-scary-teacher-integrity.cloudfunctions.net/crashRateCheck",
        reqBody
      );

      if (!response || !response.data || !response.data.data) {
        console.error("Invalid response format:", response);
        throw new Error("Invalid response format");
      }

      const data = response.data.data;
      const anrData = data.anrData;
      const crashRateData = data.CrashRateData;

      const combinedData = [];
      console.log(anrData.rows && crashRateData.rows);
      if (anrData.rows && crashRateData.rows) {
        console.log("in the rows function", anrData.rows);
        anrData.rows.forEach((anrRow) => {
          const combinedRow = {
            startTime: anrRow.startTime,
            versionCode: anrRow.dimensions[0].stringValue,
            anrRate: parseFloat(
              anrRow.metrics.find((metric) => metric.metric === "anrRate")
                .decimalValue.value
            ),
            anrRate7dUserWeighted: parseFloat(
              anrRow.metrics.find(
                (metric) => metric.metric === "anrRate7dUserWeighted"
              ).decimalValue.value
            ),
            userPerceivedAnrRate: parseFloat(
              anrRow.metrics.find(
                (metric) => metric.metric === "userPerceivedAnrRate"
              ).decimalValue.value
            ),
          };

          const crashRow = crashRateData.rows.find((crashRow) => {
            const crashRowDate = new Date(
              crashRow.startTime.year,
              crashRow.startTime.month - 1,
              crashRow.startTime.day
            );
            const anrRowDate = new Date(
              anrRow.startTime.year,
              anrRow.startTime.month - 1,
              anrRow.startTime.day
            );
            return (
              crashRowDate.getTime() === anrRowDate.getTime() &&
              crashRow.dimensions[0].stringValue === combinedRow.versionCode
            );
          });

          if (crashRow) {
            combinedRow.crashRate7dUserWeighted = parseFloat(
              crashRow.metrics.find(
                (metric) => metric.metric === "crashRate7dUserWeighted"
              ).decimalValue.value
            );
            combinedRow.crashRate = parseFloat(
              crashRow.metrics.find((metric) => metric.metric === "crashRate")
                .decimalValue.value
            );
            combinedRow.userPerceivedCrashRate = parseFloat(
              crashRow.metrics.find(
                (metric) => metric.metric === "userPerceivedCrashRate"
              ).decimalValue.value
            );
            combinedData.push(combinedRow);
          }
        });
        console.log(combinedData, "combined Data");
        const uniqueVersionCodes = combinedData
          .map((item) => item.versionCode)
          .filter((value, index, self) => self.indexOf(value) === index);
        const versionOptions = uniqueVersionCodes.map((version) => ({
          value: version,
          label: version,
        }));
        console.log(versionOptions);
        setVersions([{ value: "all", label: "All" }, ...versionOptions]);
        const sortedData = combinedData.sort((a, b) => {
          const dateA = new Date(
            a.startTime.year,
            a.startTime.month - 1,
            a.startTime.day
          );
          const dateB = new Date(
            b.startTime.year,
            b.startTime.month - 1,
            b.startTime.day
          );
          return dateB - dateA;
        });

        let temp =
          selectedVersion?.value === "all"
            ? sortedData
            : sortedData.filter(
                (row) => row.versionCode === selectedVersion?.value
              );

        console.log(temp, selectedVersion, "Filtered data");

        setTableData(temp);
        let sumAnr = 0;

        let sumCrash = 0;

        let sumUPANRRate = 0;

        let sumUPCrashRate = 0;

        let avgAnr = 0;

        let avgCrash = 0;

        let avgUPANRRate = 0;

        let avgUPCrashRate = 0;
        sortedData.forEach((row) => {
          sumAnr = sumAnr + row.anrRate * 100;

          sumCrash = sumCrash + row.crashRate * 100;

          sumUPANRRate = sumUPANRRate + row.anrRate7dUserWeighted * 100;

          sumUPCrashRate = sumUPCrashRate + row.crashRate7dUserWeighted * 100;
        });

        avgAnr = sumAnr / temp.length;

        avgCrash = sumCrash / temp.length;

        avgUPANRRate = sumUPANRRate / temp.length;

        avgUPCrashRate = sumUPCrashRate / temp.length;

        setSummaryAvg({
          avgAnrRate: avgAnr.toFixed(2),

          avgCrashRate: avgCrash.toFixed(2),

          avgUserPerceivedCrashRate: avgUPCrashRate.toFixed(2),

          avgUserPerceivedAnrRate: avgUPANRRate.toFixed(2),
        });

        setMainTableData(sortedData);

        setGameName(selectedGame.value);

        setLoading("ready");

        setLoading1("ready");

        setTableLoading("ready");

        setRefresh(true);

        setDownload(true);
      } else {
        setLoading(null);
        setTableLoading(null);
      }
    } catch (e) {
      console.error("Error during refreshContent:", e);
      setLoading(null);
      setTableLoading(null);
    }
  };

  const applyFilters = async (e) => {
    if (e) e.preventDefault();
    // console.log(gamesList);
    // console.log(selectedGame, "Selected Game");
    // console.log(gamesList[selectedGame?.value]?.key);
    // console.log(gamesList[selectedGame?.value]);
    // return ;
    try {
      setLoading("pending");
      setTableData([]);
      setTableLoading("pending");

      if (startDate === null || endDate === null) {
        console.log("Invalid Date");
        toast.error("Invalid Date", {
          position: "top-right",
          autoClose: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
        setLoading(null);
        setLoading1(null);
        setTableLoading(null);
        return;
      }
      console.log(gamesList[selectedGame.value].key);
      const reqBody = {
        startDate: startDate.format("DD-MM-YYYY"),
        endDate: endDate.format("DD-MM-YYYY"),
        name: gamesList[selectedGame.value].key,
        studioId: gamesList[selectedGame.value].studioName,
        version: "1.0",
      };
      console.log(reqBody);
      console.log("response data before the url hit ");
      // http://127.0.0.1:5001/scary-teacher-integrity/us-central1/crashRateCheck
      // https://us-central1-scary-teacher-integrity.cloudfunctions.net/crashRateCheck
      const response = await axios.post(
        "https://us-central1-scary-teacher-integrity.cloudfunctions.net/crashRateCheck",
        reqBody
      );
      console.log("response data after the url hit ");
      const data = response.data;
      const anrData = data.data.anrData;
      const crashRateData = data.data.CrashRateData;
      console.log(anrData);
      console.log(crashRateData);
      const combinedData = [];
      console.log(anrData);
      if (anrData.rows && crashRateData.rows) {
        anrData.rows.forEach((anrRow) => {
          const combinedRow = {
            startTime: anrRow.startTime,
            versionCode: anrRow.dimensions[0].stringValue,
            anrRate: parseFloat(
              anrRow.metrics.find((metric) => metric.metric === "anrRate")
                .decimalValue.value
            ),
            anrRate7dUserWeighted: parseFloat(
              anrRow.metrics.find(
                (metric) => metric.metric === "anrRate7dUserWeighted"
              ).decimalValue.value
            ),

            userPerceivedAnrRate: parseFloat(
              anrRow.metrics.find(
                (metric) => metric.metric === "userPerceivedAnrRate"
              ).decimalValue.value
            ),
            userPerceivedAnrRate: parseFloat(
              anrRow.metrics.find(
                (metric) => metric.metric === "userPerceivedAnrRate"
              ).decimalValue.value
            ),
            // userPerceivedCrashRate : parseFloat(
            //     anrRow.metrics.find(
            //         (metric) => metric.metric === "userPerceivedCrashRate"
            //     ).decimalValue.value
            // ),
          };

          const crashRow = crashRateData.rows.find((crashRow) => {
            const crashRowDate = new Date(
              crashRow.startTime.year,
              crashRow.startTime.month - 1,
              crashRow.startTime.day
            );
            const anrRowDate = new Date(
              anrRow.startTime.year,
              anrRow.startTime.month - 1,
              anrRow.startTime.day
            );
            return (
              crashRowDate.getTime() === anrRowDate.getTime() &&
              crashRow.dimensions[0].stringValue === combinedRow.versionCode
            );
          });

          if (crashRow) {
            combinedRow.crashRate7dUserWeighted = parseFloat(
              crashRow.metrics.find(
                (metric) => metric.metric === "crashRate7dUserWeighted"
              ).decimalValue.value
            );
            combinedRow.crashRate = parseFloat(
              crashRow.metrics.find((metric) => metric.metric === "crashRate")
                .decimalValue.value
            );
            combinedRow.userPerceivedCrashRate = parseFloat(
              crashRow.metrics.find(
                (metric) => metric.metric === "userPerceivedCrashRate"
              ).decimalValue.value
            );

            combinedData.push(combinedRow);
            console.log(combinedRow, "combined Row");
          }
        });
        console.log(combinedData, "combined Data");
        const uniqueVersionCodes = combinedData
          .map((item) => item.versionCode)
          .filter((value, index, self) => self.indexOf(value) === index);
        const versionOptions = uniqueVersionCodes.map((version) => ({
          value: version,
          label: version,
        }));
        console.log(versionOptions);
        setVersions([{ value: "all", label: "All" }, ...versionOptions]);
        const sortedData = combinedData.sort((a, b) => {
          const dateA = new Date(
            a.startTime.year,
            a.startTime.month - 1,
            a.startTime.day
          );
          const dateB = new Date(
            b.startTime.year,
            b.startTime.month - 1,
            b.startTime.day
          );
          return dateB - dateA;
        });
        console.log("sorted data....!", sortedData);
        setTableData(sortedData);
        setGraphLabels(
          sortedData.map((row) => {
            return moment([
              row.startTime.year,
              row.startTime.month - 1,
              row.startTime.day,
            ]).format("DD-MM-YYYY");
          })
        );

        let sumAnr = 0;
        let sumCrash = 0;
        let sumUPANRRate = 0;
        let sumUPCrashRate = 0;
        let avgAnr = 0;
        let avgCrash = 0;
        let avgUPANRRate = 0;
        let avgUPCrashRate = 0;
        sortedData.forEach((row) => {
          sumAnr = sumAnr + row.anrRate * 100;
          sumCrash = sumCrash + row.crashRate * 100;
          sumUPANRRate = sumUPANRRate + row.anrRate7dUserWeighted * 100;
          sumUPCrashRate = sumUPCrashRate + row.crashRate7dUserWeighted * 100;
        });
        avgAnr = sumAnr / sortedData.length;
        avgCrash = sumCrash / sortedData.length;
        avgUPANRRate = sumUPANRRate / sortedData.length;
        avgUPCrashRate = sumUPCrashRate / sortedData.length;
        setSummaryAvg({
          avgAnrRate: avgAnr.toFixed(2),
          avgCrashRate: avgCrash.toFixed(2),
          avgUserPerceivedCrashRate: avgUPCrashRate.toFixed(2),
          avgUserPerceivedAnrRate: avgUPANRRate.toFixed(2),
        });

        setMainTableData(sortedData);
        setGameName(selectedGame.value);
        setLoading("ready");
        setLoading1("ready");
        setTableLoading("ready");
        setRefresh(true);
        setDownload(true);
        setSelectedVersion({
          label: "All",
          value: "all",
        });
      } else {
        setLoading(null);
        setTableLoading(null);
      }
    } catch (e) {
      console.log(e);
      flag = true;
      setLoading(null);
      setTableLoading(null);
    }
  };
  const loadGames = async () => {
    const gamesCollection = collection(db, "reporting_games");
    const gamesSnapshot = await getDocs(gamesCollection);
    return gamesSnapshot.docs.map((doc) => doc.data());
  };
  const renderDay = (props, currentDate, selectedDate, startDate, _endDate) => {
    const endDate = moment(_endDate).subtract(24, "hours");

    let classes = props.className;
    if (startDate && endDate && startDate._d + "" === currentDate._d + "") {
      classes += " start-date";
    } else if (
      startDate &&
      endDate &&
      new Date(startDate._d + "") < new Date(currentDate._d + "") &&
      new Date(endDate._d + "") > new Date(currentDate._d + "")
    ) {
      classes += " middle-date";
    } else if (endDate && endDate._d + "" === currentDate._d + "") {
      classes += " end-date";
    }
    return (
      <td {...props} className={classes}>
        {currentDate.date()}
      </td>
    );
  };
  const columns = [
    {
      name: "Date",
      width: "10%",
      selector: (row) => (
        <div
          className={
            (row.anrRate * 100 > 1 || row.crashRate * 100 > 0.5) &&
            "text-danger"
          }
        >
          {moment([
            row.startTime.year,
            row.startTime.month - 1,
            row.startTime.day,
          ]).format("DD-MM-YYYY")}
        </div>
      ),
    },
    {
      name: "Name",
      selector: (row) => (
        <div
          className={
            (row.anrRate * 100 > 1 || row.crashRate * 100 > 0.5) &&
            "text-danger"
          }
        >
          {gameName || ""}
        </div>
      ),
      width: "20%",
    },
    {
      name: "Version",
      selector: (row) => (
        <div
          className={
            (row.anrRate * 100 > 1 || row.crashRate * 100 > 0.5) &&
            "text-danger"
          }
        >
          {row.versionCode}
        </div>
      ),
      width: "10%",
      center: true,
    },
    {
      name: "ANR Rate",
      selector: (row) => (
        <div
          className={
            (row.anrRate * 100 > 1 || row.crashRate * 100 > 0.5) &&
            "text-danger"
          }
        >
          {(row.anrRate * 100).toFixed(2) + " %"}
        </div>
      ),
      center: true,
    },
    {
      name: "UP ANRs",
      selector: (row) => (
        <div
          className={
            (row.anrRate * 100 > 1 || row.crashRate * 100 > 0.5) &&
            "text-danger"
          }
        >
          {(row.userPerceivedAnrRate * 100).toFixed(2) + " %"}
        </div>
      ),
      center: true,
    },
    {
      name: "7Days Avg ANR",
      selector: (row) => (
        <div
          className={
            (row.anrRate * 100 > 1 || row.crashRate * 100 > 0.5) &&
            "text-danger"
          }
        >
          {(row.anrRate7dUserWeighted * 100).toFixed(2) + " %"}
        </div>
      ),
      center: true,
    },
    {
      name: "Crash Rate",
      selector: (row) => (
        <div
          className={
            (row.anrRate * 100 > 1 || row.crashRate * 100 > 0.5) &&
            "text-danger"
          }
        >
          {(row.crashRate * 100).toFixed(2) + " %"}
        </div>
      ),
      center: true,
    },

    {
      name: "UP CrashRate",
      selector: (row) => (
        <div
          className={
            (row.anrRate * 100 > 1 || row.crashRate * 100 > 0.5) &&
            "text-danger"
          }
        >
          {(row.userPerceivedCrashRate * 100).toFixed(2) + " %"}
        </div>
      ),
      center: true,
    },

    {
      name: "7Days Avg CrashRate",
      selector: (row) => (
        <div
          className={
            (row.anrRate * 100 > 1 || row.crashRate * 100 > 0.5) &&
            "text-danger"
          }
        >
          {(row.crashRate7dUserWeighted * 100).toFixed(2) + " %"}
        </div>
      ),
      center: true,
    },
  ];

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9" fluid>
        <Row className="mb-4">
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Form onSubmit={applyFilters}>
                  <h2 className="mb-0">Filters</h2>
                  <Row>
                    <Col md={3}>
                      <h5 className="text-muted">Game</h5>
                      <Select
                        className="py-1"
                        classNamePrefix="select"
                        required
                        // defaultValue={options[0]}
                        value={selectedGame}
                        // isDisabled={isDisabled}
                        isLoading={false}
                        // isClearable={true}
                        isSearchable={true}
                        name="color"
                        options={gamesNames}
                        onChange={(selectedOption) => {
                          setSelectedGame(selectedOption);
                        }}
                      />
                    </Col>

                    <Col md={3}>
                      <h5 className="text-muted">Dates</h5>
                      <Select
                        className="py-1"
                        classNamePrefix="select"
                        required
                        // defaultValue={options[0]}
                        value={selectedPreDefinedDate}
                        // isDisabled={isDisabled}
                        isLoading={false}
                        // isClearable={true}
                        name="dateSelect"
                        options={preDefinedDates}
                        onChange={(selectedOption) => {
                          setSelectedPreDefinedDate(selectedOption);
                        }}
                      />
                    </Col>

                    {selectedPreDefinedDate &&
                    selectedPreDefinedDate?.value === "custom" ? (
                      <>
                        <Col md={3}>
                          <h5 className="text-muted">From</h5>
                          <FormGroup>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-calendar-grid-58" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <ReactDatetime
                                inputProps={{
                                  placeholder: "DD/MM/YYYY",
                                }}
                                timeFormat={false}
                                renderDay={(props, currentDate, selectedDate) =>
                                  renderDay(
                                    props,
                                    currentDate,
                                    selectedDate,
                                    startDate,
                                    endDate
                                  )
                                }
                                onChange={(e) => setStartDate(e)}
                                isValidDate={(current) => {
                                  return current.isBefore(endDate);
                                }}
                                value={startDate}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col md={3}>
                          <h5 className="text-muted">To</h5>
                          <FormGroup>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-calendar-grid-58" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <ReactDatetime
                                inputProps={{
                                  placeholder: "DD/MM/YYYY",
                                }}
                                timeFormat={false}
                                renderDay={(props, currentDate, selectedDate) =>
                                  renderDay(
                                    props,
                                    currentDate,
                                    selectedDate,
                                    startDate,
                                    endDate
                                  )
                                }
                                onChange={(e) => setEndDate(e)}
                                isValidDate={(current) => {
                                  return current.isBefore(endDate);
                                }}
                                value={endDate}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </>
                    ) : null}
                  </Row>
                  <Row className="align-items-end">
                    {tableData.length > 0 && (
                      <Col md={2}>
                        <h5 className="text-muted">Version</h5>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          // required
                          value={selectedVersion}
                          // isDisabled={isDisabled}
                          isLoading={false}
                          // isClearable={true}
                          isSearchable={true}
                          name="color"
                          options={versions}
                          onChange={(selectedOption) => {
                            setSelectedVersion(selectedOption);
                            if (selectedOption.value === "all") {
                              setTableData(mainTableData);
                            } else {
                              const dataToFilter = mainTableData.filter(
                                (row) =>
                                  row.versionCode === selectedOption.value
                              );
                              setTableData(dataToFilter);
                            }
                            console.log(selectedOption);
                          }}
                        />
                      </Col>
                    )}
                    <Col className="text-right">
                      <Button color="danger" onClick={clearFilters}>
                        Clear
                      </Button>
                      {!refresh ? (
                        <Button color="primary" type="submit">
                          {loading === "pending" ? (
                            <div
                              className="spinner-border spinner-border-sm"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : (
                            <span className="fs-6">Apply</span>
                          )}
                        </Button>
                      ) : null}

                      {tableData.length > 0 && refresh ? (
                        <Button color="primary" onClick={refreshContent}>
                          {loading1 === "pending" ? (
                            <div
                              className="spinner-border spinner-border-sm"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : (
                            <span className="fs-6">Refresh</span>
                          )}
                        </Button>
                      ) : null}

                      {tableData.length > 0 && download ? (
                        <Button color="primary">
                          <CSVLink
                            data={csv}
                            style={{ color: "white" }}
                            filename={"Crashlytics Report"}
                            onClick={csvFunction}
                          >
                            Export CSV
                          </CSVLink>
                        </Button>
                      ) : null}
                    </Col>
                  </Row>
                </Form>
              </CardHeader>
              <CardBody>
                {tableData.length > 0 && tableLoading === "ready" && (
                  <div className="mb-4">
                    <Line options={options} data={data} />
                  </div>
                )}
                <div>
                  {tableData.length > 0 && tableLoading === "ready" && (
                    <>
                      <h2 className="mb-0">Summary</h2>
                      <table className="table">
                        <tr>
                          <th>Name</th>
                          <th>Avg ANR Rate</th>
                          <th>Avg Crash Rate</th>
                          <th>Bad Behaviour (CR)</th>
                          <th>Bad Behaviour (ANR)</th>
                        </tr>
                        <tr>
                          <td>{gameName}</td>
                          <td>{summaryAvg.avgAnrRate} %</td>
                          <td>{summaryAvg.avgCrashRate} %</td>

                          <td>
                            {summaryAvg.avgUserPerceivedCrashRate >= 1.09 ? (
                              <span
                                className="text-danger"
                                title={`user perceived crash rate exceeded bad behaviour threshold, exceeds ${summaryAvg.avgUserPerceivedCrashRate} % of devices`}
                              >
                                True ({summaryAvg.avgUserPerceivedCrashRate} %)
                              </span>
                            ) : (
                              <span
                                className="text-success"
                                title={"App is behaving normally"}
                              >
                                False ({summaryAvg.avgUserPerceivedCrashRate} %)
                              </span>
                            )}
                          </td>

                          <td>
                            {summaryAvg.avgUserPerceivedAnrRate >= 0.47 ? (
                              <span
                                className="text-danger"
                                title={`user perceived ANR Rate exceeded bad behaviour threshold, exceeds ${summaryAvg.avgUserPerceivedAnrRate} % of devices`}
                              >
                                True ({summaryAvg.avgUserPerceivedAnrRate} %)
                              </span>
                            ) : (
                              <span
                                className="text-success"
                                title={"App is behaving normally"}
                              >
                                False ({summaryAvg.avgUserPerceivedAnrRate} %)
                              </span>
                            )}
                          </td>
                        </tr>
                      </table>
                    </>
                  )}
                </div>
                <div>
                  <h2>Details</h2>
                  <hr
                    style={{
                      marginTop: "-10px",
                    }}
                  />
                  <DataTable
                    columns={columns}
                    data={tableData}
                    progressPending={tableLoading === "pending"}
                    pagination
                    responsive
                    persistTableHead
                    highlightOnHover
                    className="table"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
